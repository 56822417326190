import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory as history } from "@reach/router"

function SEO({ description, lang, meta, image: metaImage, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            title
            description
            siteUrl
            metaImageUrl
          }
        }
      }
      `
  )

  const metaDescription = description || site.siteMetadata.description

  const image = `${site.siteMetadata.metaImageUrl}`
  // metaImage && metaImage.src
  //     ? `${site.siteMetadata.siteUrl}${metaImage.src}`
  //     : `${site.siteMetadata.metaImageUrl}`

  const { location, navigate } = history
  const canonical = pathname
      ? `${site.siteMetadata.siteUrl}${pathname}` 
      : `${site.siteMetadata.siteUrl}${location.pathname}`

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        [
          {
            rel: "canonical",
            href: canonical,
          },
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:type`,
          content: `website`
        },
        {
          property: "og:image",
          content: image,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        }
      ]
        .concat(
          metaImage
          ? [
            {
              property: "og:image:width",
              content: metaImage.width,
            },
            {
              property: "og:image:height",
              content: metaImage.height,
            }
          ]
        : [
            {
              property: "og:image:width",
              content: "1200",
            },
            {
              property: "og:image:height",
              content: "600",
            }
          ]
        )
        .concat(meta)}
    />
  )
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO
